jQuery(document).ready(function($) {
  // ready start

(function () {
  // Плавная прокрутка по ссылке
  var smooth_scrolling_to_anchor = {
    init: function () {
      this.listeners();
    },
    listeners: function () {
      var hash = window.location.hash;
      if (hash != '') {
        smooth_scrolling_to_anchor.scrolling(hash);
      }
      // ко всем ссылкам навигации по якорю добавить атрибут data-anchor
      $('[data-anchor]').on('click', function () {
        var address = $(this).attr('href');
        smooth_scrolling_to_anchor.scrolling(address);
      });
    },
    scrolling: function (address) {
      // анимируем скроллинг
      var el = $(address);
      var target = el[0];
      $('html, body').stop().animate({'scrollTop': target.offsetTop}, 1000);
    }
  }
  smooth_scrolling_to_anchor.init();
}());

(function () {
  // MOBILE MENU
  var mobile_menu = {
    init: function () {
      this.listeners();
    },
    listeners: function () {
      $('#mobile-ico').on('click', mobile_menu.show);
      $('#menu-close').on('click', mobile_menu.close);
      $('.menu__link').on('click', mobile_menu.item);
    },
    show: function (event) {
      event.preventDefault();
      $('#menu').addClass('active');
    },
    close: function (event) {
      event.preventDefault();
      $('#menu').removeClass('active');
    },
    item: function (event) {
      if ( $('#menu').hasClass('active') ) {
        mobile_menu.close(event);
      }
    }
  }
  mobile_menu.init();
}());

(function () {
  // SLICK
  var slick = {
    init: function () {
      this.listeners();
    },
    listeners: function () {
      var $slider = $('#slider-license');
      $slider.slick({
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: '<button type="button" class="slick-prev">&#10094;</button>',
        nextArrow: '<button type="button" class="slick-next">&#10095;</button>',
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
        ]
      });
    },
  }
  slick.init();
}());

(function () {
  // FANCYBOX
  var fancybox = {
    init: function () {
      this.listeners();
    },
    listeners: function () {
      $("[data-fancybox=license]").fancybox({
        toolbar : false,
      });
    }
  }
  fancybox.init();
}());

(function () {
  // MODAL
  var order = {
    init: function () {
      this.listeners();
    },
    listeners: function () {
      $('.order-btn').on('click', function(event) {
        event.preventDefault();
        var type = $(this).attr('data-order');
        order.show(type);
      });
    },
    show: function (type) {
      var $modal = $('#modal');
      $modal.find('input[name=type]').val(type);
      $.fancybox.open( $modal, $modal.index( this ) );
    }
  }
  order.init();
}());

(function () {
    // FORM HANDLER
    var forms = {
        init: function () {
            this.listeners();
        },
        listeners: function () {
            $('input[name=phone]').mask("+7(999) 999-9999");
            // валидация форм
            $.validate({
                lang: 'ru',
                scrollToTopOnError: false,
                borderColorOnError : '#b71c1c',
            });
            // ловим отправку формы
            $('body').on('submit', 'form', forms.send_form);
        },
        send_form: function (event) {
            event.preventDefault();
            // отправляем форму

            var form = $(this); // сохраняем форму
            var action = 'php/forms.php';
            var data = $(form).serialize(); // данные формы

            $.post(action, data, function(response) {
                var ans = $.parseJSON(response);

                if ( ans.success ) {
                    // если ответ true
                    $(form).get(0).reset(); // сбрасываем форму
                    $(form).find('.result').html('<div class="success">'+ ans.msg +'</div>'); // показываем ответ
                    setTimeout(function() {
                        $(form).find('.result').html('');
                    }, 5000); // скрываем ответ
                } else {
                    // если ответ false
                    $(form).find('.result').html('<div class="error">' + ans.msg + '</div>'); // показываем ошибку
                }
            });
        }
    }
    forms.init();
}());

  // ready end
});